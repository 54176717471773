<template>
  <div>
    <TagInput
      :disabled="disabled"
      label="Copy Names Mode"
      :value="selectedItems"
      :items="copyNameModeOptions"
      @input="handleInput"
    />
  </div>
</template>

<script>
import TagInput from "@/components/common/TagInput.vue";

export default {
  components: { TagInput },
  props: ["value", "disabled"],
  data() {
    return {
      copyNameModeOptions: [
        {
          displayName: "Lab",
          id: 1
        },
        {
          displayName: "Secondary Provider",
          id: 2
        },
        {
          displayName: "Copy Names Type Code",
          id: 4
        }
      ],
      selectedItems: []
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        let returnValue = [];
        nv = parseInt(nv);
        if ([0, 1, 2, 4].includes(nv)) {
          returnValue = [nv];
        }
        // TODO: Make this better
        if (nv === 7) {
          returnValue = [1, 2, 4];
        }
        if (nv === 3) {
          returnValue = [1, 2];
        }
        if (nv === 5) {
          returnValue = [1, 4];
        }
        if (nv === 6) {
          returnValue = [2, 4];
        }
        this.selectedItems = returnValue;
      }
    }
  },
  methods: {
    handleInput(value) {
      const sum = value.reduce((partialSum, a) => partialSum + a, 0);
      this.$emit("input", sum || null);
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="printerDistribution align-items-start">
    <div class="mr-4">
      <div class="">
        <label for="printer">
          <b>Printer</b>
        </label>
        <select type="text" class="form-control" id="printer" name="printerId">
          <option value="">Contact Printers</option>
          <option v-for="printer in labPrinters" :key="printer.id" :value="printer.printerId">
            {{ printer.displayName }}
          </option>
        </select>
        <CopyModesSelector
          class="mt-2"
          :value="copyNameMode"
          :disabled="labCopyOnly"
          @input="handleUpdateCopyNameMode"
        />
      </div>
    </div>

    <fieldset class="mr-4">
      <legend class="">Print Order</legend>
      <div>
        <input required type="radio" name="printOrder" id="location" value="0" />
        <label for="location"><b>Location</b></label>
      </div>
      <div>
        <input required type="radio" name="printOrder" id="pathologist" value="1" />
        <label for="pathologist"><b>Pathologist</b></label>
      </div>
      <div>
        <input required checked type="radio" name="printOrder" id="deptNumber" value="2" />
        <label for="deptNumber"><b>Dept. Number</b></label>
      </div>
      <div>
        <input required type="radio" name="printOrder" id="route" value="3" />
        <label for="route"><b>Route</b></label>
      </div>
      <div>
        <input required type="radio" name="printOrder" id="physician" value="4" />
        <label for="physician"><b>Physician</b></label>
      </div>
    </fieldset>
    <Checkbox
      label="Lab Copy Only"
      :value="labCopyOnly"
      @input="handleUpdateLabCopyOnly"
      name="labCopyOnly"
      id="labCopyOnly"
    />

    <!-- <fieldset>
      <legend>Copies</legend>
      <div>
        <input type="radio" value="0" name="copyName" id="labCopyOnly" /><label for="labCopyOnly"
          ><b>Lab Copy</b></label
        >
      </div>
      <div>
        <input checked type="radio" name="copyName" value="1" id="labCopy" /><label for="labCopy"
          ><b>Multiple Copies</b></label
        >
      </div>
      <div>
        <input type="radio" name="copyName" value="2" id="secondaryProviderCopy" /><label
          for="secondaryProviderCopy"
          ><b>Secondary Provider Copy</b></label
        >
      </div>
    </fieldset> -->
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox.vue";
import CopyModesSelector from "../Selectors/CopyModesSelector.vue";

export default {
  props: ["labPrinters", "labCopyOnly", "copyNameMode"],
  components: { Checkbox, CopyModesSelector },
  methods: {
    handleUpdateLabCopyOnly(value) {
      this.$emit("labCopy", value);
    },
    handleUpdateCopyNameMode(value) {
      this.$emit("copyNameMode", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.printerDistribution {
  display: flex;
  flex-wrap: wrap;
}
</style>

<template>
  <container>
    <!-- IP-1548 - rename page to Batch Printing -->
    <page-title class="p-2"> Batch Printing </page-title>
    <form @submit.prevent="handleSubmit" class="mb-3 col py-2">
      <div class="mb-2 row">
        <SelectInput
          class="col-3"
          name="mode"
          v-model="mode"
          id="mode"
          :items="modes"
          label="Mode"
        />
        <!-- IP-1548 - remove options other than batch printing -->
        <!-- <SelectInput class="col" name="type" v-model="type" id="type" :items="types" label="Type" /> -->
      </div>
      <printing
        :labPrinters="labPrinters"
        :copyNameMode="copyNameMode"
        :labCopyOnly="labCopyOnly"
        @labCopy="handleLabCopy"
        @copyNameMode="handleUpdateCopyNameMode"
      />
      <!-- IP-1548 - remove options other than batch printing -->
      <!-- <faxing v-if="type === DistributionMethodsEnum.Fax" ref="faxing" /> -->
      <!-- <emailing v-if="type === DistributionMethodsEnum.Email" /> -->
      <div class="my-2">
        <dx-grid-with-search
          title="Batch Printing"
          :toolbar="toolbar"
          :noDataText="'Please select options and filter, to begin.'"
          class="my-2"
          :dataSource="dataSource"
          :columns="columns"
          :remoteOperations="remoteOperations"
          :headerFilter="headerFilter"
          :filterRow="filterRow"
          :selection="selection"
          @initialized="initializeGrid"
          :pageSize="25"
          gridName="batchDistribution"
        >
          <template v-slot:extraActions>
            <div>
              <icon-button
                type="button"
                @click="handleApplyFilters"
                class="btn ml-auto btn-primary mx-1"
                icon="search"
              >
                Apply Filters
              </icon-button>
              <button
                :disabled="(mode === 2 && !isFiltered) || isLoading"
                type="submit"
                class="btn btn-primary mx-2"
              >
                Print
              </button>
            </div>
          </template>
        </dx-grid-with-search>
        <div class="row px-2 justify-content-end">
          <loader size="small" class="ml-auto" v-show="isLoading" />
          <button
            :disabled="(mode === 2 && !isFiltered) || isLoading"
            type="submit"
            class="btn btn-primary mx-2"
          >
            Print
          </button>
        </div>
      </div>
    </form>
    <modal :status="isViewerOpen" @close="isViewerOpen = false">
      <PDFViewer
        class="viewer"
        @print="handlePrint"
        @download="handlePdfDownload"
        :url="pdfUrl"
        @close="isViewerOpen = false"
      />
    </modal>
  </container>
</template>

<script>
import Modal from "../components/common/Modal.vue";
import PDFViewer from "../components/common/PDFViewer.vue";
import PathReportMessage from "../services/pathReportMessages";
import Reports from "../services/Reports";
import { mapState } from "vuex";
import { format } from "date-fns";
import printJS from "print-js";
import { AuditLogApi, PrintersApi } from "@/services";
import Printing from "@/components/forms/BatchDistribution/Printing.vue";
import DataSource from "devextreme/data/data_source";
// IP-1548 - remove options other than batch printing
// import Faxing from "@/components/forms/BatchDistribution/Faxing.vue";
// import Emailing from "@/components/forms/BatchDistribution/Emailing.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import {
  createLocalTzDateFilter,
  createLogItem,
  filterAccessionNumber,
  formatDatetimeCell
} from "@/modules/helpers";
import PageTitle from "@/components/common/PageTitle.vue";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import Loader from "@/components/common/Loader.vue";
import { AuditLogItems, DistributionMethodsEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
const ModesEnum = {
  Initial: 1,
  ReDistribute: 2,
  ReviewCopies: 3
};
export default {
  components: {
    Modal,
    PDFViewer,
    Printing,
    SelectInput,
    // IP-1548 - remove options other than batch printing
    // Faxing,
    // Emailing,
    DxGridWithSearch,
    PageTitle,
    Container,
    IconButton,
    Loader
  },
  created() {
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = res || [];
      this.$nextTick(() => {
        this.setDefaultBatchPrinter();
      });
    });
    // IP-1548 - remove all options except printing
    // this.type = this.DefaultBatchMessageType ?? this.type;
    if (this.defaultBatchPrintMode) {
      this.mode = this.defaultBatchPrintMode;
    }
    if (this.mode === ModesEnum.Initial && this.type === DistributionMethodsEnum.Print) {
      this.isFiltered = true;
    }
  },
  mounted() {
    this.loadDefaultCopyNameMode();
  },
  data() {
    return {
      isViewerOpen: false,
      step: 0,
      pdfUrl: "",
      startDate: "",
      toolbar: {
        items: [{ location: "after", template: "extraActions" }]
      },
      grid: {},
      mode: 1,
      DistributionMethodsEnum,
      type: DistributionMethodsEnum.Print,
      targetId: null,
      labPrinters: [],
      isFiltered: false,
      isLoading: false,
      endDate: new Date(),
      headerFilter: {
        visible: true
      },
      filterRow: {
        visible: true
      },
      selection: {
        enabled: true,
        allowSelectAll: true,
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "always"
      },
      remoteOperations: {
        paging: true,
        filtering: true,
        sorting: true,
        grouping: false
      },
      ModesEnum,
      modes: [
        {
          displayName: "Initial",
          id: 1
        },
        {
          displayName: "Re-Distribute",
          id: 2
        },
        {
          displayName: "Review Copies",
          id: 3
        }
      ],
      types: [
        {
          displayName: "Fax",
          id: 1
        },
        {
          displayName: "Email",
          id: 2
        },
        {
          displayName: "Print",
          id: 3
        },
        {
          displayName: "HL7",
          id: 4
        },
        {
          displayName: "FileDrop",
          id: 5
        }
      ],
      typeNames: {
        1: "Faxed On",
        2: "Emailed On",
        3: "Printed On",
        4: "HL7 Sent On",
        5: "File Drop Sent On"
      },
      labCopyOnly: false,
      copyNameMode: null
    };
  },
  metaInfo: {
    // IP-1548 - rename to Batch Printing
    title: "Batch Printing",
    titleTemplate: "IntelliPath - %s"
  },
  watch: {
    mode(nv, ov) {
      if (this.mode === ModesEnum.ReviewCopies) {
        this.isFiltered = true;
      } else if (nv !== ov || nv === null) {
        this.isFiltered = false;
      }
    }
  },
  methods: {
    initializeGrid({ component }) {
      this.grid = component;
    },
    handleApplyFilters() {
      this.isFiltered = true;
      this.grid.filter([this.sourceFilters, "and", ["messageTypeId", this.type]]);
      this.grid.refresh(true);
    },
    async handleSubmit(event) {
      if (!this.isFiltered) {
        const confirm = await window.confirm(
          "You have not filtered the grid, and may have a lot of items. Are you sure you want to continue?"
        );
        if (!confirm) return;
      }
      const payload = { method: this.type, isBatch: true };
      const formData = new FormData(event.target);
      if (payload.method === DistributionMethodsEnum.Fax) {
        //Fax
        payload.recipients = [
          {
            address: formData.get("faxNumber"),
            displayName: formData.get("recipient"),
            type: 1
          }
        ];
        payload.isHighPriority = this.$refs.faxing.isHighPriority;
      }
      if (payload.method === DistributionMethodsEnum.Email) {
        // Email
        const { to, cc } = event.target.elements;

        payload.subject = formData.get("subject");
        payload.body = formData.get("body");
        payload.recipients = [
          ...Array.from(to.options).map(option => ({
            address: option.value,
            type: 1
          })),
          ...Array.from(cc.options).map(option => ({
            address: option.value,
            type: 2
          }))
        ];
      }
      if (payload.method === DistributionMethodsEnum.Print) {
        // Print
        payload.targetId = formData.get("printerId");
        payload.primarySort = formData.get("printOrder");
        payload.copyNameMode = this.copyNameMode;
        payload.isReviewCopy = this.mode === ModesEnum.ReviewCopies;
      }
      payload.caseIds = this.grid.getSelectedRowKeys();
      if (!payload.caseIds.length) {
        payload.caseIds = (
          await this.batchStore.load({
            filter: this.grid.getCombinedFilter(),
            select: "caseId"
          })
        ).map(e => e.caseId);
      }
      const { BatchPrintMinCaseQtyCausesWarning, BatchPrintMinCaseQtyCausesError } =
        this.labSettings;
      if (payload.caseIds.length > Number(BatchPrintMinCaseQtyCausesError ?? 25)) {
        window.alert(
          `Exceeds Batch Print Min Case Qty Causes Error value of ${BatchPrintMinCaseQtyCausesError}.`
        );
        return;
      }
      if (payload.caseIds.length > Number(BatchPrintMinCaseQtyCausesWarning ?? 5)) {
        const confirm = await window.confirm(
          `You have ${payload.caseIds.length} cases selected. <br> Do you wish to continue?`
        );
        if (!confirm) {
          return;
        }
      }
      try {
        this.isLoading = true;
        await PathReportMessage.addPathReportMessage(payload);
        const distributionMethod = this.findDistributionMethodEnum(payload.method);
        // IP-1548 - rename to Batch Printing
        const logItem = createLogItem({}, distributionMethod, "Batch Printing");
        logItem.comments = JSON.stringify(payload, null, 2);
        AuditLogApi.insertLogMessage(logItem);
        window.notify(`Printed ${payload.caseIds.length} cases(s).`);
        this.grid.refresh(true);
        this.grid.clearSelection();
      } catch (error) {
        // IP-1548 - rename to Batch Printing
        console.log("Error in batch printing", error);
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleCancel() {
      const confirm = await window.confirm(
        "Any unsaved data will be lost. <br> Are you sure you wish to continue?"
      );
      if (!confirm) {
        return;
      }
      this.grid.clearFilter();
      this.grid.refresh(true);
    },
    getPathReportPDf({ data }) {
      const { pathReportId } = data;
      Reports.getPathReportById(pathReportId).then(pdf => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
        this.isViewerOpen = true;
      });
    },
    handlePdfDownload() {
      var a = document.createElement("a");
      a.href = this.pdfUrl;
      a.download = `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORT.pdf`;
      a.click();
    },

    handlePrint() {
      ///Make call for logging.
      printJS({
        printable: this.pdfUrl,
        type: "pdf",
        documentTitle: `${format(new Date(), "MM/dd/yyyy")}_PATH_REPORT.pdf`
      });
    },
    async resubmitMessage({ data }) {
      const { id } = data;
      const confirm = await window.confirm(
        `<p class="text-center m-auto">The accession will be resent by this distribution method in its current state not in the state it was sent in when it was sent previously.<br>
        Are you sure you want to resend?</p>`
      );
      if (!confirm) {
        return;
      }
      return PathReportMessage.resubmitMessage(id).then(() => window.notify("Success!"));
    },
    cancelMessage({ data }) {
      const { id } = data;
      return PathReportMessage.cancelMessage(id).then(() => window.notify("Success!"));
    },
    findDistributionMethodEnum(method) {
      if (method === DistributionMethodsEnum.Fax) {
        return AuditLogItems.Fax;
      }
      if (method === DistributionMethodsEnum.Email) {
        return AuditLogItems.Email;
      }
      if (method === DistributionMethodsEnum.Print) {
        return AuditLogItems.Print;
      }
      if (method === DistributionMethodsEnum.HL7) {
        return AuditLogItems.HL7;
      }
      if (method === DistributionMethodsEnum.FileDrop) {
        return AuditLogItems.FileDrop;
      }
    },
    calculateReportedOn(reportedOn) {
      if (reportedOn) {
        const formattedDate = formatDatetimeCell(reportedOn);
        return format(formattedDate, "M/dd/yyyy");
      }
      return "";
    },
    handleLabCopy(value) {
      this.labCopyOnly = value;
      if (value) {
        this.copyNameMode = 1;
      }
    },
    handleUpdateCopyNameMode(value) {
      this.copyNameMode = value;
    },
    loadDefaultCopyNameMode() {
      if (this.DefaultCopyNamesMode) {
        this.copyNameMode = this.DefaultCopyNamesMode;
      }
    },
    setDefaultBatchPrinter() {
      if (this.defaultBatchPrinter) {
        const selector = document.getElementById("printer");
        selector.value = this.defaultBatchPrinter;
      }
    }
  },
  computed: {
    ...mapState({
      userTz: state => state.userTz,
      labSettings: state => state.labSettings,
      numberingType: state => state.labSettings.AccessionNumberingType,
      caseStatuses: state => state.caseStatuses,
      DefaultBatchMode: state => state.labSettings.DefaultBatchMessageType,
      DefaultCopyNamesMode: state => state.labSettings.DefaultCopyNamesMode,
      defaultBatchPrinter: state => state.applicationSettings.defaultBatchPrinter,
      defaultBatchPrintMode: state => state.applicationSettings.defaultBatchPrintMode
    }),
    dataSource() {
      if (!this.isFiltered) {
        return [];
      }
      return new DataSource({
        store: this.batchStore,
        key: "caseId",
        filter:
          this.mode === ModesEnum.ReviewCopies
            ? null
            : [this.sourceFilters, "and", ["messageTypeId", this.type]]
      });
    },
    batchStore() {
      /** IP-309 Includes the target PRM type  as a qs param*/
      return PathReportMessage.createSearch(
        `/api/BatchPathReportMessages?mt=${this.type}`,
        "caseId",
        {
          rd: this.mode === ModesEnum.ReDistribute, //Targets the store to show you redistributions only.
          mt: this.type, //IP-309 mt = Message Type
          rc: this.mode === ModesEnum.ReviewCopies,
          sp: [2, 3, 7, 6].includes(this.copyNameMode)
        }
      );
    },
    sourceFilters() {
      return this.mode === ModesEnum.Initial
        ? ["prmAttachmentId", null]
        : ["!", ["prmAttachmentId", null]];
    },
    columns() {
      return [
        {
          dataField: "caseNumber",
          dataType: "string",
          caption: "Case #",
          sortIndex: 0,
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "prefix",
          dataType: "string"
        },
        {
          dataField: "caseStatusId",
          caption: "Case Status",
          allowFiltering: true,
          dataType: "string",
          lookup: {
            dataSource: this.caseStatuses.map((status, idx) => ({ id: idx, displayName: status })),
            displayExpr: "displayName",
            valueExpr: "id"
          },
          filterValues: this.mode === ModesEnum.Initial ? [10, 7, 13, 8, 14, 11] : []
        },
        {
          dataField: "provider",
          caption: "Provider",
          dataType: "string"
        },
        {
          dataField: "pathologist",
          caption: "Pathologist",
          dataType: "string"
        },
        {
          dataField: "receivedOn",
          dataType: "date",
          sortOrder: "desc"
        },
        {
          dataField: "lastSignedOnDate",
          dataType: "date",
          caption: "Reported Date",
          calculateDisplayValue: data => {
            if (data.lastSignedOnDate) {
              return this.calculateReportedOn(data.lastSignedOnDate);
            }
            return "";
          }
        },
        {
          dataField: "providerLocation",
          dataType: "string",
          caption: "Location"
        },
        {
          dataField: "route",
          dataType: "string"
        },
        {
          dataField: "reportedOn",
          caption: this.typeNames[this.type],
          visible: this.mode !== 1,
          dataType: "date",
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "reportedOn");
          }
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .viewer {
  width: 42.5vw;
}
::v-deep fieldset {
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      margin-right: 5px;
    }
    label {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
  &.col-4 {
    & > div {
      margin-bottom: 0.5rem;
    }
  }
  legend {
    font-size: 1.1rem;
    margin: 0;
    font-weight: 500;
    width: max-content;
  }
  .options-fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
h6 {
  margin-top: -5px;
}
</style>
